// custom typefaces
import "typeface-montserrat"
import "typeface-merriweather"

// Gatsby code titles
require('./src/styles/global.css')

// Default prismjs themes
require("prismjs/themes/prism.css")

// Custom prismjs themes
//require("./src/styles/themes/prism-material-light.css")
